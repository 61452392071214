<template>
  <div class="detail">
    <div class="section">
      <div class="part">
        <div class="title">
          <div class="line"></div>
          学生信息
        </div>
      </div>
      <div class="card">
        <div class="detailItem">
          <div class="itemTitle">姓名：</div>
          <div class="itemContent">{{ detailInfo.xm }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">性别：</div>
          <div class="itemContent">{{ detailInfo.xb == 1 ? "男" : "女" }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">{{ zjlx }}：</div>
          <div class="itemContent">{{ detailInfo.zjh }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">出生日期：</div>
          <div class="itemContent" v-if="detailInfo.csrq">
            {{ detailInfo.csrq | dateFilter }}
          </div>
        </div>
        <div class="detailItem" v-if="detailInfo.djType == 2">
          <div class="itemTitle">学籍号：</div>
          <div class="itemContent">{{ detailInfo.xjh }}</div>
        </div>
        <div class="detailItem" v-if="detailInfo.djType == 2">
          <div class="itemTitle" v-if="detailInfo.djType == 1">
            就读幼儿园：
          </div>
          <div class="itemTitle" v-if="detailInfo.djType == 2">就读小学：</div>
          <div class="itemContent">{{ detailInfo.jdxx }}</div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="part">
        <div class="title">
          <div class="line"></div>
          户籍信息
        </div>
      </div>
      <div class="card">
        <div class="detailItem">
          <div class="itemTitle">户籍地：</div>
          <div class="itemContent">
            {{ detailInfo.hjProvinceText }} {{ detailInfo.hjCityText }}
            {{ detailInfo.hjCountyText }}
          </div>
        </div>
        <!-- <div class="detailItem">
          <div class="itemTitle">户籍登记详细地址：</div>
          <div class="itemContent">{{detailInfo.hjAddress}}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">户籍所在地派出所：</div>
          <div class="itemContent">{{detailInfo.hjSzdpcs}}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">户口登记（迁入）日期：</div>
          <div class="itemContent">{{detailInfo.hjDjrq | dateFilter}}</div>
        </div> -->

        <!-- <div class="detailItem file" v-if="detailInfo.hkbUrl">
          <div class="itemTitle">户口簿附件：</div>
          <div class="itemContent">
            <div class="img" v-for="(img, index) in detailInfo.hkbUrl.split(',')" :key="index">
              <img :src="imgOrigin + img"
                @click="previewImage(imgOrigin + img)">
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="section">
      <div class="part">
        <div class="title">
          <div class="line"></div>
          居住地详细地址信息
        </div>
      </div>
      <div class="card">
        <div class="detailItem">
          <div class="itemTitle">家庭住址：</div>
          <div class="itemContent">
            {{ detailInfo.jzdProvinceText }} {{ detailInfo.jzdCityText }}
            {{ detailInfo.jzdCountyText }}
          </div>
        </div>
        <div
          class="detailItem"
          v-if="detailInfo.jzdCountyCode == systemAreaCode"
        >
          <div class="itemTitle">家庭住址所属街道：</div>
          <div class="itemContent">{{ detailInfo.jzdStreet }}</div>
        </div>
        <div
          class="detailItem"
          v-if="detailInfo.jzdCountyCode == systemAreaCode"
        >
          <div class="itemTitle">家庭住址所属社区（村）：</div>
          <div class="itemContent">{{ detailInfo.jzdArea }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">居住地详细地址：</div>
          <div class="itemContent">{{ detailInfo.jzdAddress }}</div>
        </div>
        <!-- <div class="detailItem">
          <div class="itemTitle">居住地所属学区：</div>
          <div class="itemContent">{{detailInfo.jzdSsxqName}}</div>
        </div> -->
        <div class="detailItem">
          <div class="itemTitle">就读意向学校类型：</div>
          <div class="itemContent">{{ detailInfo.yxSchoolTypeText }}</div>
        </div>
        <div
          class="detailItem"
          v-if="detailInfo.djType == 1 && detailInfo.sspqName"
        >
          <div class="itemTitle">片区：</div>
          <div class="itemContent">{{ detailInfo.sspqName }}</div>
        </div>
        <div
          class="detailItem"
          v-if="
            detailInfo.yxSchoolType == 1 &&
            detailInfo.djType == 1 &&
            detailInfo.ssjdName
          "
        >
          <div class="itemTitle">学校所属街道：</div>
          <div class="itemContent">{{ detailInfo.ssjdName }}</div>
        </div>
        <div
          class="detailItem"
          v-if="
            detailInfo.yxSchoolType == 1 &&
            detailInfo.djType == 1 &&
            detailInfo.yxSchoolName
          "
        >
          <div class="itemTitle">就读意向学校名称：</div>
          <div class="itemContent">{{ detailInfo.yxSchoolName }}</div>
        </div>
      </div>
    </div>
    <!-- 图片预览 -->
    <a-modal
      :width="800"
      :visible="previewVisible"
      :footer="null"
      @cancel="imgHandleCancel"
    >
      <img alt="example" style="width: 100%" :src="imgUrl" />
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { getDetail } from "@/api/form";
import { getListBySignList } from "@/api/api";

export default {
  data() {
    return {
      imgOrigin: this.$store.state.fileUrl,
      detailInfo: {},
      previewVisible: false, // 图片预览
      imgUrl: "",
      systemAreaCode: localStorage.getItem("systemAreaCode"),
      zjlx: "",
    };
  },
  filters: {
    dateFilter(val) {
      return moment(+val).format("YYYY-MM-DD");
    },
  },
  methods: {
    previewImage(url) {
      this.previewVisible = true;
      this.imgUrl = url;
    },
    imgHandleCancel() {
      this.previewVisible = false;
    },
    getDetailInfo() {
      getDetail().then((res) => {
        this.detailInfo = res.data;
      });
    },
    getDic() {
      getListBySignList({
        types: 804001,
      }).then((res) => {
        console.log(res.data[0].dicts, "10,");
        res.data[0].dicts.forEach((item) => {
          if (item.value == this.detailInfo.zjlx) {
            this.zjlx = item.name;
          }
          console.log(this.zjlx, "99,");
        });
      });
    },
  },
  mounted() {
    this.getDetailInfo();
    setTimeout(() => {
      this.getDic();
    }, 50);
  },
};
</script>

<style lang="less" scoped>
.detail {
  padding: 16px 16px 0;
}
.section {
  .part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7f7f7;
    padding: 8px 16px;
    margin-bottom: 20px;
    margin-top: 16px;
  }
  .title {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .line {
    display: inline-block;
    width: 3px;
    height: 16px;
    background: #35ab61;
    border-radius: 2px;
    margin-right: 6px;
  }
  .del {
    font-size: 14px;
    color: #ff5454;
  }
}
.card {
  display: flex;
  flex-wrap: wrap;
  .item {
    display: flex;
    flex-wrap: wrap;
  }
  .familyItem {
    width: 100%;
    .num {
      font-size: 14px;
      font-weight: 600;
      color: #666;
      margin-bottom: 20px;
    }
  }
  .detailItem {
    display: flex;
    width: 49%;
    font-size: 14px;
    margin-right: 1%;
    margin-bottom: 16px;
  }
  .file {
    width: 100%;
    .itemContent {
      display: flex;
      flex-wrap: wrap;
    }
    .img {
      margin-right: 8px;
      margin-bottom: 8px;
    }
    img {
      width: 68px;
      height: 68px;
    }
  }
  .itemTitle {
    color: #666;
  }
  .itemContent {
    color: #333;
  }
}
</style>
