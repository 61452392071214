<template>
  <div class="page">
    <div class="form">
      <a-form id="mbxxForm" :form="form" ref="mbxxForm">
        <div class="section">
          <div class="part">
            <div class="title"><span class="line"></span>学生信息</div>
          </div>
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="学生姓名">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  disabled
                  v-decorator="[
                    'xm',
                    {
                      rules: [{ required: true, message: '请输入学生姓名' }],
                    },
                  ]"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="性别">
                <a-radio-group
                  disabled
                  v-decorator="[
                    'xb',
                    {
                      rules: [{ required: true, message: '请选择性别' }],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <a-radio value="1"> 男 </a-radio>
                  <a-radio value="2"> 女 </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="证件号码">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  disabled
                  v-decorator="[
                    'zjh',
                    {
                      rules: [{ required: true, message: '请输入证件号码' }],
                    },
                  ]"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="出生日期">
                <a-date-picker
                  size="large"
                  style="width: 100%"
                  disabled
                  v-decorator="[
                    'csrq',
                    {
                      rules: [{ required: true, message: '请选择出生日期' }],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="80" v-if="stage == 2">
            <a-col :span="12">
              <a-form-item label="学籍号">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  placeholder="请输入学籍号"
                  v-decorator="[
                    'xjh',
                    {
                      rules: [{ required: true, message: '请输入学籍号' }],
                      validateTrigger: 'change',
                    },
                  ]"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="stage == 2">
              <a-form-item :label="stage == 1 ? '就读幼儿园' : '就读小学'">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  placeholder="请输入就读学校"
                  v-decorator="[
                    'jdxx',
                    {
                      rules: [{ required: true, message: '请输入就读学校' }],
                      validateTrigger: 'change',
                    },
                  ]"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <div class="section">
          <div class="part">
            <div class="title"><span class="line"></span>户籍信息</div>
          </div>
          <a-row :gutter="80">
            <a-col :span="24">
              <a-row :gutter="24">
                <a-col :span="8">
                  <a-form-item label="户籍地">
                    <a-select
                      size="large"
                      placeholder="请选择户籍地省"
                      disabled
                      v-decorator="[
                        'hjProvinceText',
                        {
                          rules: [{ required: true }],
                        },
                      ]"
                    >
                      <a-select-option
                        v-for="item in provinceList"
                        :key="item.rSname"
                        :value="item.rSname"
                      >
                        {{ item.rSname }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label=" ">
                    <a-select
                      size="large"
                      placeholder="请选择户籍地市"
                      disabled
                      v-decorator="['hjCityText']"
                    >
                      <a-select-option
                        v-for="item in cityList"
                        :key="item.rSname"
                        :value="item.rSname"
                      >
                        {{ item.rSname }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label=" ">
                    <a-select
                      size="large"
                      placeholder="请选择户籍地区（县）"
                      disabled
                      v-decorator="['hjCountyText']"
                    >
                      <a-select-option
                        v-for="item in areaList"
                        :key="item.rSname"
                        :value="item.rSname"
                      >
                        {{ item.rSname }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
            <!-- <a-col :span="12">
              <a-form-item label="户籍登记详细地址">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  placeholder="请输入户籍登记详细地址"
                  v-decorator="[
                    'hjAddress',
                    {
                      rules: [
                        { required: true, message: '请输入户籍登记详细地址' },
                      ],
                      validateTrigger: 'change',
                    },
                  ]"
                ></a-input>
              </a-form-item>
            </a-col> -->
          </a-row>
          <!-- <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="户籍所在地派出所">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  placeholder="请输入户籍所在地派出所"
                  v-decorator="[
                    'hjSzdpcs',
                    {
                      rules: [
                        { required: true, message: '请输入户籍所在地派出所' },
                      ],
                      validateTrigger: 'change',
                    },
                  ]"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="户籍登记（迁入）日期">
                <a-date-picker size="large" style="width: 100%"
                  placeholder="请选择户籍登记（迁入）日期"
                  v-decorator="[
                    'hjDjrq',
                    {
                      rules: [{ required: true, message: '请选择户籍登记（迁入）日期' }],
                      validateTrigger: 'change',
                    },
                  ]" />
              </a-form-item>
            </a-col>
          </a-row> -->
        </div>
        <div class="section">
          <div class="part">
            <div class="title"><span class="line"></span>监护人信息</div>
          </div>
          <div class="familyWrap" v-for="(info, idx) in familyInfo" :key="idx">
            <div class="familyItem">
              <div class="num">监护人({{ idx + 1 }})</div>
              <div class="dashed" v-if="idx > 0"></div>
              <div class="del" @click="delFamily(idx)" v-if="idx > 0">删除</div>
            </div>
            <div>
              <a-row :gutter="80">
                <a-col :span="12">
                  <a-form-item label="监护人姓名">
                    <a-input
                      placeholder="请输入监护人姓名"
                      size="large"
                      v-model="info.fXm"
                      :disabled="idx == 0 ? true : false"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="与适龄儿童关系">
                    <a-select
                      size="large"
                      placeholder="请选择与适龄儿童关系"
                      v-model="info.gx"
                      :disabled="idx == 0 ? true : false"
                    >
                      <a-select-option
                        v-for="item in gxList"
                        :key="item.value"
                        :value="item.value"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="80">
                <a-col :span="12">
                  <a-form-item label="身份证号">
                    <a-input
                      placeholder="请输入监护人身份证号"
                      size="large"
                      :maxLength="18"
                      v-model="info.sfzh"
                      :disabled="idx == 0 ? true : false"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="手机号">
                    <a-input
                      placeholder="请输入监护人手机号"
                      size="large"
                      :maxLength="11"
                      v-model="info.lxdh"
                      :disabled="idx == 0 ? true : false"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="80">
                <a-col :span="12">
                  <a-form-item label="工作单位类型">
                    <a-select
                      size="large"
                      placeholder="请选择工作单位类型"
                      v-model="info.dwType"
                      :disabled="idx == 0 ? true : false"
                    >
                      <a-select-option
                        v-for="item in dwTypeList"
                        :key="item.value"
                        :value="item.value"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
          </div>
          <a-button
            type="dashed"
            size="large"
            v-if="familyInfo.length < 2"
            style="width: 100%; border-color: #35ab61; color: #35ab61"
            @click="addFamily"
          >
            <a-icon type="plus" /> 添加监护人
          </a-button>
        </div>
        <div class="section">
          <div class="part">
            <div class="title">
              <span class="line"></span>居住地详细地址信息
            </div>
          </div>
          <a-row :gutter="80">
            <a-col :span="24">
              <a-row :gutter="24">
                <a-col :span="8">
                  <a-form-item label="居住地址-省">
                    <a-select
                      size="large"
                      placeholder="请选择省"
                      @change="provinceChange"
                      v-decorator="[
                        'jzdProvinceText',
                        {
                          rules: [{ required: true, message: '请选择' }],
                          validateTrigger: 'change',
                        },
                      ]"
                    >
                      <a-select-option
                        v-for="item in provinceList"
                        :key="item.rSname"
                        :value="item.rSname"
                      >
                        {{ item.rSname }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="居住地址-市">
                    <a-select
                      size="large"
                      placeholder="请选择市"
                      @change="cityChange"
                      v-decorator="[
                        'jzdCityText',
                        {
                          rules: [{ required: true, message: '请选择' }],
                          validateTrigger: 'change',
                        },
                      ]"
                    >
                      <a-select-option
                        v-for="item in cityList"
                        :key="item.rSname"
                        :value="item.rSname"
                      >
                        {{ item.rSname }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="居住地址-区（县）">
                    <a-select
                      size="large"
                      placeholder="请选择区（县）"
                      @change="areaChange"
                      v-decorator="[
                        'jzdCountyText',
                        {
                          rules: [{ required: true, message: '请选择' }],
                          validateTrigger: 'change',
                        },
                      ]"
                    >
                      <a-select-option
                        v-for="item in areaList"
                        :key="item.rSname"
                        :value="item.rSname"
                      >
                        {{ item.rSname }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <!-- jzdIsArea == 1 -->

          <a-row :gutter="80" v-if="jzdIsArea == 1">
            <a-col :span="12">
              <a-form-item label="居住地所属街道">
                <a-select
                  size="large"
                  placeholder="请选择"
                  @change="jzdStreetChange"
                  v-decorator="[
                    'jzdStreet',
                    {
                      rules: [{ required: true, message: '请选择' }],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <a-select-option
                    v-for="item in jzdStreetList"
                    :key="item.jdbm"
                    :value="item.jdmc"
                  >
                    {{ item.jdmc }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="居住地所属社区（村）">
                <a-select
                  size="large"
                  placeholder="请选择"
                  @change="jzdSqChange"
                  v-decorator="[
                    'jzdArea',
                    {
                      rules: [{ required: true, message: '请选择' }],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <a-select-option
                    v-for="item in jzdSqList"
                    :key="item.jdbm"
                    :value="item.jdmc"
                  >
                    {{ item.jdmc }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="居住小区名称">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  placeholder="请输入居住小区名称"
                  v-decorator="[
                    'jzdXqmc',
                    {
                      rules: [
                        { required: false, message: '请输入居住小区名称' },
                      ],
                      validateTrigger: 'change',
                    },
                  ]"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="居住地详细地址">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  placeholder="请输入居住地详细地址"
                  v-decorator="[
                    'jzdAddress',
                    {
                      rules: [
                        { required: true, message: '请输入居住地详细地址' },
                      ],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <!-- <a-button
                    type="link"
                    class="codeBtn"
                    slot="addonAfter"
                    @click="getXq"
                    >获取学区</a-button> -->
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="80">
            <!-- <a-col :span="12">
              <a-form-item label="居住地所属学区">
                <a-select size="large" placeholder="请选择"
                  v-decorator="[
                    'jzdSsxqName',
                    {
                      rules: [
                        { required: true, message: '请选择' },
                      ],
                      validateTrigger: 'change',
                    },
                  ]">
                  <a-select-option v-for="item in xqList" :key="item.id" :value="item.pName">
                    {{item.pName}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
            <a-col :span="12">
              <a-form-item label="就读意向学校类型">
                <a-select
                  size="large"
                  placeholder="请选择"
                  @change="schoolTypeChange"
                  v-decorator="[
                    'yxSchoolType',
                    {
                      rules: [{ required: true, message: '请选择' }],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <a-select-option
                    v-for="item in schoolTypeList"
                    :key="item.id"
                    :value="item.value"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="stage == 1 && schoolType == 1">
              <a-form-item label="片区">
                <a-select
                  size="large"
                  placeholder="请选择"
                  @change="pqChange"
                  v-decorator="[
                    'sspqName',
                    {
                      rules: [{ required: true, message: '请选择' }],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <a-select-option
                    v-for="item in pqList"
                    :key="item.id"
                    :value="item.pqName"
                  >
                    {{ item.pqName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <div class="tip" v-if="pqBz">{{ pqBz }}</div>
            </a-col>
          </a-row>
          <a-row :gutter="80">
            <a-col :span="12" v-if="sfxxx == 1 && schoolType == 1">
              <a-form-item label="学校所属街道">
                <a-select
                  size="large"
                  placeholder="请选择"
                  @change="streetChange"
                  v-decorator="[
                    'ssjdName',
                    {
                      rules: [{ required: true, message: '请选择' }],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <a-select-option
                    v-for="item in streetList"
                    :key="item.jdbm"
                    :value="item.jdmc"
                  >
                    {{ item.jdmc }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="sfxxx == 1 && schoolType == 1">
              <a-form-item label="就读意向学校">
                <a-select
                  size="large"
                  placeholder="请选择"
                  @change="schoolChange"
                  v-decorator="[
                    'yxSchoolName',
                    {
                      rules: [{ required: true, message: '请选择' }],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <a-select-option
                    v-for="item in schoolList"
                    :key="item.id"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <div class="tip">
          温馨提示：<br />
          1、民办学校的选报在信息登记期间同步进行，即2023年8月5日8:00-2023年8月9日18:00。<br />
          2、信息填写过程中和保存信息后可以修改，信息提交后不能修改。<br />
          3、家长诚信承诺：以上信息填报真实有效，如有虚假，愿承担相应责任。
        </div>
        <div class="saveBtn">
          <a-row :gutter="24">
            <a-col :span="7" :offset="5">
              <a-button
                type="primary"
                shape="round"
                size="large"
                block
                class="pre-button btn"
                @click="save(1)"
              >
                保存
              </a-button>
            </a-col>
            <a-col :span="7">
              <!-- <a-popconfirm placement="top" ok-text="确认" cancel-text="取消" @confirm="save(2)">
                <template slot="title">
                  <p>温馨提示</p>
                  <p>确认提交填报信息吗？</p>
                </template>
                <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  block
                  class="btn"
                >
                  提交
                </a-button>
              </a-popconfirm> -->
              <a-button
                type="primary"
                shape="round"
                size="large"
                block
                @click="save(9)"
                class="btn"
              >
                提交
              </a-button>
            </a-col>
          </a-row>
        </div>
      </a-form>
      <!-- 提交确认框 -->
      <a-modal
        v-model="showTip"
        title="温馨提示："
        :footer="null"
        :width="480"
        :closable="true"
        :centered="true"
        :maskClosable="false"
        @cancel="cancelTip"
      >
        <div class="resultWrap">
          <a-icon class="icon" type="info-circle" theme="filled" />
          <div class="desc">
            请监护人务必保证填报信息准确无误，如确认无误，请在倒计时结束后，点击“确定提交”按钮，切记：提交后信息不能再修改！
          </div>
          <a-button
            type="primary"
            shape="round"
            size="large"
            block
            v-if="countdown"
            class="result-btn btn undone"
          >
            {{ btnText }}
          </a-button>
          <a-button
            type="primary"
            shape="round"
            size="large"
            block
            v-else
            class="result-btn btn"
            @click="save(2)"
          >
            确定提交
          </a-button>
          <a-button
            type="primary"
            shape="round"
            size="large"
            block
            class="cancel-btn btn"
            @click="cancelTip"
          >
            取消
          </a-button>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import pick from "lodash.pick";
import {
  getDetail,
  getXq,
  getStreet,
  getSchoolStreet,
  getsqList,
  getPqSchools,
  saveXxdj,
  getPq,
} from "@/api/form";
import { getCity, getDicAll } from "@/api/common";
import { telValid, idValid } from "@/utils/reg.js";

export default {
  components: {},
  data() {
    return {
      disabled: false,
      xjDisabled: false, // 学籍是否可编辑
      stage: localStorage.getItem("stage"),
      imgOrigin: this.$store.state.fileUrl,
      form: this.$form.createForm(this),
      jzdProvinceText: "", // 居住地 start
      jzdProvinceCode: "",
      jzdCityText: "",
      jzdCityCode: "",
      jzdCountyText: "",
      jzdCountyCode: "", // 居住地 end
      detailInfo: {}, // 回显值
      dicList: [],
      citys: [],
      provinceList: [], // 省
      cityList: [], // 市
      areaList: [], // 县
      xqList: [], // 学区
      schoolList: [], // 学区下的学校
      schoolTypeList: [], // 学校类型
      gxList: [], // 关系列表
      dwTypeList: [], // 工作/就业单位类型
      schoolType: "",
      familyInfo: [],
      showTip: false,
      countdown: true,
      btnText: "倒计时30s",
      codeV: "",
      streetList: [], // 街道列表
      ssjdCode: "",
      jzdStreetList: [],
      jzdStreetCode: "",
      jzdSqList: [],
      jzdIsArea: "", // 家庭住址( 1 离石  2非离石)
      pqList: [], // 片区
      sspqName: "",
      sspqId: "",
      pqBz: "", // 片区备注
      sfxxx: "", // 是否选学校 1是  2否
      systemAreaCode: localStorage.getItem("systemAreaCode"),
    };
  },
  filters: {
    dateFilter(val) {
      return moment(+val).format("YYYY-MM-DD");
    },
  },
  beforeCreate() {
    this.form.getFieldDecorator("familyList", {
      initialValue: [],
      preserve: true,
    });
  },
  methods: {
    // 监护人
    //添加监护人
    addFamily() {
      const info = {
        gx: "", // 与适龄儿童关系
        fXm: "", // 姓名
        sfzh: "", // 身份证号
        lxdh: "", // 联系电话
        dwType: "", // 单位类型
      };
      this.familyInfo.push(info);
    },
    //移除监护人
    delFamily(index) {
      this.familyInfo.splice(index, 1);
    },
    // ================
    provinceChange(e) {
      this.form.resetFields([
        "jzdCityText",
        "jzdCityCode",
        "jzdCountyCode",
        "jzdCountyText",
      ]);
      this.provinceList.forEach((pro) => {
        if (pro.rSname == e) {
          this.cityList = pro.item;
          this.jzdProvinceText = pro.rSname;
          this.jzdProvinceCode = pro.rCode;
          // 市排序处理
          let cityObj = {};
          for (var i = 0; i < this.cityList.length; i++) {
            if (this.cityList[i].rCode == 141100) {
              cityObj = this.cityList[i];
              this.cityList.splice(i, 1);
              break;
            }
          }
          this.cityList.unshift(cityObj);
        }
      });
    },
    cityChange(e) {
      this.form.resetFields(["jzdCountyCode", "jzdCountyText"]);
      this.cityList.forEach((city) => {
        if (city.rSname == e) {
          this.areaList = city.item;
          this.jzdCityText = city.rSname;
          this.jzdCityCode = city.rCode;
        }
      });
    },
    areaChange(e) {
      this.areaList.forEach((area) => {
        if (area.rSname == e) {
          this.jzdCountyText = area.rSname;
          this.jzdCountyCode = area.rCode;
        }
      });

      if (this.jzdCountyCode == this.systemAreaCode) {
        this.jzdIsArea = 1;
      } else {
        this.jzdIsArea = 2;
        this.form.resetFields([
          "jzdStreet",
          "jzdStreetCode",
          "jzdArea",
          "jzdAreaCode",
        ]);
      }
    },
    schoolTypeChange(e) {
      this.schoolType = e;
      if (this.stage == 1) {
        if (e != 1) {
          // 清空意向学校 yxSchoolName yxSchoolId  街道  ssjdCode  ssjdName
          this.form.resetFields([
            "yxSchoolName",
            "yxSchoolId",
            "ssjdCode",
            "ssjdName",
            "sspqId",
            "sspqName",
          ]);
          this.pqBz = "";
          this.sfxxx = "";
        } else {
          this.getPqList();
          this.getStreetList();
        }
      }
    },
    pqChange(e) {
      console.log(e);
      this.pqList.forEach((ele, idx) => {
        if (ele.pqName == e) {
          this.sspqId = ele.id;
          if (ele.pqBz) {
            this.pqBz = ele.pqBz;
          } else {
            this.pqBz = "";
          }
          if (ele.sfxxx) {
            this.sfxxx = ele.sfxxx;
          } else {
            this.sfxxx = "";
          }
        }
      });
      console.log(this.pqBz);
      if (this.sfxxx == 2 || this.sfxxx == "") {
        this.form.resetFields([
          "yxSchoolName",
          "yxSchoolId",
          "ssjdCode",
          "ssjdName",
        ]);
      }
    },
    // 改变地址  置空校区
    // changeAddress(e) {
    //   this.xqList = []
    //   this.form.resetFields(['jzdSsxqId', 'jzdSsxqName'])
    // },
    // 获取学区
    // getXq() {
    //   const jzdAddress = this.form.getFieldValue('jzdAddress')
    //   if (!jzdAddress) {
    //     this.$message.error("请填写居住地详细地址");
    //     return;
    //   }
    //   getXq({
    //     jzdAddress: jzdAddress
    //   }).then(res => {
    //     this.xqList = []
    //     this.xqList = res.data
    //     const jzdSsxqId = this.form.getFieldValue('jzdSsxqId')
    //     if (!jzdSsxqId) {
    //       this.form.setFieldsValue({jzdSsxqId: this.xqList[0].id+""});
    //       this.form.setFieldsValue({jzdSsxqName: this.xqList[0].pName+""});
    //     }
    //   })
    // },
    // 获取片区
    getPqList() {
      getPq({}).then((res) => {
        this.pqList = res.data;
        if (this.pqList.length > 0 && this.sspqName) {
          this.pqList.forEach((ele, idx) => {
            if (ele.pqName == this.sspqName) {
              this.sspqId = ele.id;
              if (ele.pqBz) {
                this.pqBz = ele.pqBz;
              } else {
                this.pqBz = "";
              }
              if (ele.sfxxx) {
                this.sfxxx = ele.sfxxx;
              } else {
                this.sfxxx = "";
              }
            }
          });
        }
      });
    },
    // 获取街道
    getStreetList() {
      getSchoolStreet({}).then((res) => {
        this.streetList = res.data;
        this.getSchoolList();
      });
    },
    getJzdStreetList() {
      getStreet({}).then((res) => {
        this.jzdStreetList = res.data;
      });
    },
    getJzdSqList() {
      getsqList({
        pbm: this.jzdStreetCode,
      }).then((res) => {
        this.jzdSqList = res.data;
      });
    },
    streetChange(e) {
      this.streetList.forEach((ele, idx) => {
        if (ele.jdmc == e) {
          this.ssjdCode = ele.jdbm;
        }
      });
      this.form.resetFields(["yxSchoolName", "yxSchoolId"]);
      if (this.ssjdCode) {
        this.getSchoolList();
      }
    },
    jzdStreetChange(e) {
      this.jzdStreetList.forEach((ele, idx) => {
        if (ele.jdmc == e) {
          this.jzdStreetCode = ele.jdbm;
        }
      });
      this.form.resetFields(["jzdArea", "jzdAreaCode"]);
      if (this.jzdStreetCode) {
        this.getJzdSqList();
      }
    },
    jzdSqChange(e) {},
    // 意向学校
    getSchoolList() {
      getPqSchools({
        xqId: -999,
        djType: localStorage.getItem("stage"),
        planId: this.detailInfo.planId,
        yxSchoolType: this.schoolType,
        ssjdCode: this.ssjdCode,
        sspqId: this.sspqId,
      }).then((res) => {
        this.schoolList = res.data;
        if (this.sfxxx == 1) {
          this.setFormData();
        }
      });
    },
    schoolChange(e) {
      // yxSchoolId 参数
      console.log(e);
    },
    getDetailInfo() {
      getDetail().then((res) => {
        this.detailInfo = res.data;
        this.schoolType = res.data.yxSchoolType;
        this.sspqId = res.data.sspqId;
        this.sspqName = res.data.sspqName;

        if (res.data.yxSchoolType == 1) {
          this.getPqList();
          this.getStreetList();
        }

        this.familyInfo = res.data.familyList;
        this.detailInfo.csrq = moment(res.data.csrq).format("YYYY-MM-DD");
        if (res.data.hjDjrq) {
          this.detailInfo.hjDjrq = moment(res.data.hjDjrq).format("YYYY-MM-DD");
        }
        this.jzdProvinceText = this.detailInfo.jzdProvinceText;
        this.jzdCityText = this.detailInfo.jzdCityText;
        this.jzdCountyText = this.detailInfo.jzdCountyText;
        this.jzdProvinceCode = this.detailInfo.jzdProvinceCode;
        this.jzdCityCode = this.detailInfo.jzdCityCode;
        this.jzdCountyCode = this.detailInfo.jzdCountyCode;
        if (this.provinceList.length > 0) {
          this.provinceList.forEach((pro) => {
            if (pro.rSname == this.jzdProvinceText) {
              this.cityList = pro.item;
            }
          });
        }
        if (this.cityList.length > 0) {
          this.cityList.forEach((city) => {
            if (city.rSname == this.jzdCityText) {
              this.areaList = city.item;
            }
          });
        }
        if (this.jzdCountyCode == this.systemAreaCode) {
          this.jzdIsArea = 1;
        } else {
          this.jzdIsArea = 2;
        }
        this.setFormData();
      });
    },
    save(type) {
      const NAME_REGEX= /^[\u4e00-\u9fa5]+$/
      this.form.validateFields((err, values) => {
        values.id = this.detailInfo.id;
        // 保存 1  提交 2
        values.xxdjStatus = type;
        // 监护人信息
        if (this.familyInfo.length > 0) {
          for (let j = 0; j < this.familyInfo.length; j++) {
            if (!this.familyInfo[j].fXm || this.familyInfo[j].fXm == "") {
              this.$message.error("请填写监护人2姓名");
              return;
            }
            console.log('姓名校验',NAME_REGEX.test(this.familyInfo[j].fXm));
            if (!NAME_REGEX.test(this.familyInfo[j].fXm)) {
              this.$message.error("请填写监护人2姓名不能包含特殊字符");
              return;
            }
            if (!this.familyInfo[j].gx || this.familyInfo[j].gx == "") {
              this.$message.error("请选择监护人2与适龄儿童关系");
              return;
            }
            if (!this.familyInfo[j].sfzh || this.familyInfo[j].sfzh == "") {
              this.$message.error("请填写监护人2身份证");
              return;
            }
            if (this.familyInfo[j].sfzh) {
              if (!idValid(this.familyInfo[j].sfzh.trim())) {
                this.$message.error("请填写正确的身份证号");
                return;
              }
            }
            if (!this.familyInfo[j].lxdh || this.familyInfo[j].lxdh == "") {
              this.$message.error(`请填写监护人${j}联系电话`);
              return;
            }
            if (this.familyInfo[j].lxdh) {
              if (!telValid(this.familyInfo[j].lxdh.trim())) {
                this.$message.error("请填写正确格式的手机号码");
                return;
              }
            }
            if (!this.familyInfo[j].dwType || this.familyInfo[j].dwType == "") {
              this.$message.error("请选择监护人2工作单位类型");
              return;
            }
          }

          if (this.familyInfo[0].gx == this.familyInfo[1].gx || this.familyInfo[0].sfzh == this.familyInfo[1].sfzh) {
             this.$message.error(`监护人信息不能相同`);
             return;
          }

        }
        values.familyList = this.familyInfo;
        this.streetList.forEach((ele, idx) => {
          if (ele.jdmc == values.ssjdName) {
            values.ssjdCode = ele.jdbm;
          }
        });
        this.pqList.forEach((ele, idx) => {
          if (ele.pqName == values.sspqName) {
            values.sspqId = ele.id;
          }
        });
        if (this.jzdIsArea == 1) {
          this.jzdStreetList.forEach((ele, idx) => {
            if (ele.jdmc == values.jzdStreet) {
              values.jzdStreetCode = ele.jdbm;
            }
          });
          this.jzdSqList.forEach((ele, idx) => {
            if (ele.jdmc == values.jzdArea) {
              values.jzdAreaCode = ele.jdbm;
            }
          });
        }
        this.schoolList.forEach((ele, idx) => {
          if (ele.name == values.yxSchoolName) {
            values.yxSchoolId = ele.id;
          }
        });
        this.schoolTypeList.forEach((ele, idx) => {
          if (ele.value == values.yxSchoolType) {
            values.yxSchoolTypeText = ele.name;
          }
        });
        values.schoolCode = localStorage.getItem("schoolCode");
        values.djType = localStorage.getItem("stage");
        values.djTypeValue = localStorage.getItem("stageText");

        // 非农村学校 置空意向学校选择
        if (values.yxSchoolType != 1) {
          values.yxSchoolName = "";
          values.yxSchoolId = -1;
          values.ssjdName = "";
          values.ssjdCode = -1;
        }

        values.jzdProvinceText = this.jzdProvinceText;
        values.jzdCityText = this.jzdCityText;
        values.jzdCountyText = this.jzdCountyText;
        values.jzdProvinceCode = this.jzdProvinceCode;
        values.jzdCityCode = this.jzdCityCode;
        values.jzdCountyCode = this.jzdCountyCode;
        values.planId = this.detailInfo.planId;

        console.log("888", values);

        if (!err) {
          if (type == 9) {
            // 显示提交提醒
            this.showTip = true;
            // 按钮倒计时 30s
            const _this = this;
            let coden = 30; // 定义30秒的倒计时
            this.codeV = setInterval(function () {
              _this.btnText = "倒计时" + --coden + "s";
              if (coden == -1) {
                // 清除setInterval倒计时，按钮变回原样
                clearInterval(this.codeV);
                _this.countdown = false;
                _this.btnText = "确定提交";
              }
            }, 1000);
          } else if (type == 1 || type == 2) {
            saveXxdj(values).then((res) => {
              if (res.code == 0) {
                this.$message.success(res.description);
                if (type == 2) {
                  this.$emit("reload");
                }
              } else {
                this.$message.error(res.description);
              }
            });
          }
        }
      });
    },
    cancelTip() {
      this.showTip = false;
      clearInterval(this.codeV);
      this.btnText = "倒计时30s";
      this.countdown = true;
    },
    getCity() {
      getCity().then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            res.data.forEach((element) => {
              this.provinceList.push(element);
            });
            // 省份排序处理
            let proObj = {};
            for (var i = 0; i < this.provinceList.length; i++) {
              if (this.provinceList[i].rCode == 140000) {
                proObj = this.provinceList[i];
                this.provinceList.splice(i, 1);
                break;
              }
            }
            this.provinceList.unshift(proObj);
          }
          this.getDetailInfo();
        } else {
          this.$message.error(res.description);
        }
      });
    },
    getDic() {
      const _this = this;
      getDicAll({
        schoolCode: localStorage.getItem("schoolCode"),
        types: "803003,804003,804006",
      }).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            res.data.filter(function (item) {
              // 学校类型
              if (item.code == 803003) {
                _this.schoolTypeList = item.dicts;
              }
              // 身份证件类型
              if (item.code == 804003) {
                _this.gxList = item.dicts;
              }
              // 工作/就业单位类型
              if (item.code == 804006) {
                _this.dwTypeList = item.dicts;
              }
            });
          }
          this.getJzdStreetList();
          this.getCity();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    setFormData() {
      const formData = pick(this.detailInfo, [
        "id",
        "xb",
        "xm",
        "zjh",
        "csrq",
        "hjProvinceText",
        "hjProvinceCode",
        "hjCityCode",
        "hjCityText",
        "hjCountyText",
        "hjCountyCode",
        "jzdProvinceText",
        "jzdProvinceCode",
        "jzdCityCode",
        "jzdCityText",
        "jzdCountyText",
        "jzdCountyCode",
        "jzdAddress",
        "jzdSsxqId",
        "jzdSsxqName",
        "ssqy",
        "hjAddress",
        "hjSzdpcs",
        "hjDjrq",
        "xjh",
        "jdxx",
        "familyList",
        "yxSchoolId",
        "yxSchoolName",
        "ssjdCode",
        "ssjdName",
        "jzdStreet",
        "jzdStreetCode",
        "jzdArea",
        "jzdAreaCode",
        "jzdXqmc",
        "yxSchoolTypeText",
        "yxSchoolType",
        "sspqName",
        "sspqId",
      ]);
      this.$nextTick(() => {
        this.form.setFieldsValue(formData);
      });
    },
  },
  mounted() {
    this.getDic();
  },
};
</script>

<style lang="less" scoped>
.tip {
  color: #ff7575;
  font-size: 14px;
}
.notice {
  .conWrap {
    padding: 52px 77px 32px;
    .title {
      color: #333;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 24px;
    }
  }
  .btnWrap {
    text-align: center;
  }
  .btn {
    margin: 24px auto 16px;
    width: 280px;
    height: 44px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  }
}
.section {
  .part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7f7f7;
    padding: 8px 16px;
    margin-bottom: 12px;
    margin-top: 28px;
  }
  .title {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .line {
    display: inline-block;
    width: 3px;
    height: 16px;
    background: #35ab61;
    border-radius: 2px;
    margin-right: 6px;
  }
  .scan {
    font-size: 14px;
    color: #35ab61;
    cursor: pointer;
  }
  .del {
    font-size: 14px;
    color: #ff5454;
  }
  .icon {
    margin-right: 6px;
  }
  .lookDemo {
    font-size: 14px;
    color: #35ab61;
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 8px;
  }
}
.ant-radio-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 40px;
  line-height: 27px;
  padding: 6px 11px;
  width: 186px;
  &:last-child {
    margin-right: 0;
  }
}
.familyItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .num {
    font-size: 14px;
    color: #666;
    font-weight: 600;
    &::before {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      line-height: 1;
      content: "*";
    }
  }
  .dashed {
    flex-grow: 1;
    border-top: 1px dashed #ddd;
    margin: 0 16px;
  }
  .del {
    font-size: 14px;
    color: #ff5454;
    border-radius: 4px;
    border: solid 1px #ff9b9b;
    padding: 1px 9px;
    cursor: pointer;
  }
}
/deep/ .ant-form-item-control {
  line-height: inherit;
}
/deep/ .ant-form-item {
  margin-bottom: 12px;
}
/deep/ .ant-input {
  font-size: 14px;
}
/deep/ .ant-form-item-label > label::after {
  content: "";
}
.saveBtn {
  margin-top: 28px;
  margin-bottom: 16px;
  .btn {
    height: 44px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  }
  .pre-button {
    border-color: #35ab61;
    background: #fff;
    color: #35ab61;
    box-shadow: none;
  }
}
.resultWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    font-size: 48px;
    color: #ffba00;
    margin-bottom: 20px;
  }
  .title {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 14px;
  }
  .desc {
    font-size: 14px;
    color: #666;
  }
  .result-btn {
    width: 280px;
    margin-top: 36px;
    margin-bottom: 16px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
    &.undone {
      opacity: 0.6;
    }
  }
  .cancel-btn {
    width: 280px;
    border-color: #35ab61;
    background: #fff;
    color: #35ab61;
    box-shadow: none;
    margin-bottom: 16px;
  }
}
</style>
