<template>
  <div class="headerWrap">
    <img class="img1" :src="require('@/assets/home/topBg.png')" alt="" />
    <img class="img2" :src="require('@/assets/home/topBg2.png')" alt="" />
    <img class="img3" :src="require('@/assets/home/topBg3.png')" alt="" />
    <div class="inner">
      <div class="homeHeader">
        <div class="left">
          <div class="title">
            <!-- <img class="logo" :src="require('@/assets/home/logo.png')" alt="" /> -->
            <img class="logo" :src="`/${sysLogo}`" alt="" />
            <div class="name">{{sysName}}</div>
          </div>
          <div class="nav" v-if="showNav">
            <ul>
              <li
                v-for="(item, index) in navList"
                :key="index"
                :class="activeIndex == index ? 'active' : ''"
              >
                <router-link :to="item.path">{{ item.name }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="operation" v-if="showRight">
          <div class="item">
            <a-dropdown placement="bottomCenter">
              <a class="ant-dropdown-link">
                <!-- <a-icon class="userIcon" type="user" /> -->
                {{ userName }}
                <a-icon type="caret-down" theme="filled" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <div @click="toLogout">退出登录</div>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="pageTitle">
      <div class="name">{{pageTitle}}</div>
      <div class="stage" v-if="showRight">{{stageText}}</div>
    </div>
  </div>
</template>
<script>
import { logOut } from '@/api/api';

export default {
  props: {
    activeIndex: { // 当前选中
      type: String,
    },
    pageTitle: { // 标题名称
      type: String,
    },
    showNav: { // 是否显示导航
      type: Boolean,
      default: true
    },
    showRight: { // 是否显示导航
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      // year: new Date().getFullYear(),
      userName: "",
      stageText: "",
      navList: [
        // { name: "报名流程", path: "/home" },
        // { name: "通知公告", path: "/notice" },
        // { name: "Q&A", path: "/qa" },
        // { name: "学区分布图", path: "/xqImg" },
      ],
      sysName: localStorage.getItem("sysName"),
      sysLogo: localStorage.getItem("sysLogo")
    };
  },
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem("user"));
    if (userInfo) {
      this.userName = userInfo.userName;
    }
    this.stageText = localStorage.getItem("stageText")
  },
  methods: {
    toLogout() {
      logOut().then(res => {
        if (res.code == 0) {
          this.$message.success(res.description);
          localStorage.removeItem("user");
          localStorage.removeItem("stage");
          localStorage.removeItem("stageText");
          localStorage.removeItem("bmStageId");
          setTimeout(() => {
            this.$router.push({
              name: "Enter",
            });
          }, 1000)
        } else {
          this.$message.error(res.description);
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
.headerWrap {
  width: 100%;
	height: 230px;
	background-image: linear-gradient(139deg, 
		#58be6d 0%, 
		#68d486 100%);
  position: relative;
  .img1{
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .img2{
    position: absolute;
    left: 48vw;
    bottom: 0;
  }
  .img3{
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.inner{
  padding: 2px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.homeHeader {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 52px;
  .left{
    display: flex;
    align-items: center;
  }
  .nav {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    ul {
      list-style: none;
      display: flex;
      margin: 0;
      li {
        font-size: 14px;
        margin-right: 56px;
        line-height: 52px;
        a {
          color: rgba(255, 255, 255, 0.8);
          text-decoration: none;
          display: block;
          width: 100%;
        }
      }
      .active {
        font-weight: 600;
        border-bottom: 2px solid #fff;
        a{
          color: #fff
        }
      }
    }
  }
  .title{
    display: flex;
    margin-right: 78px;
    .logo{
      width: 24px;
      height: 24px;
      border-radius: 4px;
      margin-top: 14px;
      box-shadow: 0 1px 3px 0 rgba(0,0,0,0.27);
    }
    .name{
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      margin-left: 10px;
    }
  }
  .operation {
    display: flex;
    .item {
      position: relative;
      a{
        color: #fff;
      }
      .userIcon {
        position: absolute;
        left: -20px;
        top: 3px;
      }
    }
  }
}
.pageTitle{
  max-width: 1000px;
  margin: 66px auto 0px;
  display: flex;
  align-items: center;
  color: #fff;
  .name{
    font-size: 30px;
    font-weight: 600;
    margin-right: 16px;
  }
  .stage{
    width: 69px;
	  height: 24px;
    font-size: 12px;
    background: url(~@/assets/home/stageBg.png) no-repeat;
    padding-left: 26px;
    line-height: 24px;
  }
}
</style>
