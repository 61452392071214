import { axios } from '@/utils/request'

// 获取填报详情
export function getDetail (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/getRegInfo',
    method: 'get',
    params: params
  })
}

/**
 * 信息登记 start
 */

// 通过详细地址获取学区 jzdAddress
export function getXq (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/getPrioritySchools',
    method: 'post',
    data: params
  })
}

// 获取片区
export function getPq (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/getBigPq',
    method: 'post',
    data: params
  })
}

// 获取有学校街道
export function getSchoolStreet (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/getYxxStreetList',
    method: 'post',
    data: params
  })
}

// 获取街道
export function getStreet (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/getStreetList',
    method: 'post',
    data: params
  })
}

// 获取社区（村）
export function getsqList (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/getsqList',
    method: 'get',
    params: params
  })
}

// 获取片区下的学校
export function getPqSchools (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/getPqSchools',
    method: 'get',
    params: params
  })
}

// 提交
export function saveXxdj (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/saveRegInfo',
    method: 'post',
    data: params
  })
}

// ========================================== 信息登记  end


/**
 * 民办选报 start
 */
// 民办选报详情
export function getMbDetail (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/getMbXzInfo',
    method: 'get',
    params: params
  })
}

// 获取民办学校
export function getSchoolList (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/getMbSchools',
    method: 'get',
    params: params
  })
}

// 是否显示关联派位  yxSchoolId
export function getIfGlyh (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/isNoShowGlyh',
    method: 'get',
    params: params
  })
}
// 提交
export function saveMbxb (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/tjMbXzInfo',
    method: 'post',
    data: params
  })
}

// ========================================== 民办选报  end

// 登记表预览
export function tableHtml (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/ylDjbHtml',
    method: 'get',
    params: params
  })
}

// 登记表下载记录
export function downloadInfo (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/djInfoXz',
    method: 'post',
    data: params
  })
}
