<template>
  <div class="page">
    <div class="notice" v-if="showNotice">
      <div class="conWrap">
        <div class="title">温馨提示：</div>
        <div class="con" v-html="notice.nContent" v-if="notice.nContent"></div>
        <div class="con" v-else style="text-align: center">暂未发布</div>
      </div>
      <div class="btnWrap">
        <a-button
          type="primary"
          shape="round"
          size="large"
          block
          class="btn"
          @click="know()"
        >
          我已阅读并知晓，开始填报
        </a-button>
      </div>
    </div>
    <div class="form" v-else>
      <a-form id="mbxxForm" :form="form" ref="mbxxForm">
        <div class="section">
          <div class="part">
            <div class="title"><span class="line"></span>学生信息</div>
          </div>
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="学生姓名">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  disabled
                  v-decorator="['xm']"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12" class="self_radio">
              <a-form-item label="性别">
                <a-radio-group
                  disabled
                  v-decorator="[
                    'xb',
                    {
                      rules: [{ required: true, message: '请选择性别' }],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <a-radio value="1"> 男 </a-radio>
                  <a-radio value="2"> 女 </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="证件号码">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  disabled
                  v-decorator="['zjh']"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="监护人姓名">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  disabled
                  v-decorator="['jhr']"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="监护人手机号">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  disabled
                  v-decorator="['jhrdh']"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12"> </a-col>
          </a-row>
        </div>
        <div class="section">
          <div class="part">
            <div class="title"><span class="line"></span>选报学校</div>
          </div>
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="选报民办学校">
                <a-select
                  size="large"
                  placeholder="请选择"
                  @change="schoolChange"
                  v-decorator="[
                    'yxSchoolId',
                    {
                      rules: [{ required: true, message: '请选择' }],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <a-select-option
                    v-for="item in schoolList"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <!-- <div class="tip" v-if="glyhFlag">
                检查到您入学报名儿童可能是多胞胎，且选报了同一所民办学校，请确定是否进行多胞胎关联派位。
              </div> -->
            </a-col>

            <a-col :span="12">
              <div v-if="glAbandon" class="tip">
                已放弃关联派位<br />
                重要提示：您已放弃关联派位，多人将分别独立参加派位。
              </div>
              <div v-if="glConfirm">
                <div>关联派位：</div>
                <div class="glyh-list" :style="{background:'#fff'}">
                  <div v-for="item in glyhList" :key="item.id">
                    <span>{{ item.stuXm }}：</span
                    ><span>{{ item.stuZjh }}</span>
                  </div>
                </div>

                <div class="tip">
                  重要提示：关联派位后，多人将视为一人参加派位，成功则多人均被录取，未成功则多人均未被录取。
                </div>
              </div>
            </a-col>

            <!-- 关联排位start -->
            <div class="glyh-mask" v-if="glyhModal">
              <div class="glyh-content">
                <div class="glyh-header"></div>
                <div class="glyh-body">
                  <div class="tip">
                    检查到您当前入学报名儿童可能与以下儿童是多胞胎，且选报了同一所民办学校，请确定是否进行多胞胎关联派位。
                  </div>
                  <div class="glyh-list">
                    <div v-for="item in glyhList" :key="item.id">
                      <span>{{ item.stuXm }}：</span
                      ><span>{{ item.stuZjh }}</span>
                    </div>
                  </div>
                  <div class="tip">
                    重要提示：您选择确定关联派位后，多人将视为一人参加派位，成功则多人均被录取，未成功则多人均未被录取；若放弃关联派位，则视为多人分别独立参加派位。
                  </div>
                </div>
                <div class="glyh-footer">
                  <a-space size="large">
                    <a-button
                      type=""
                      shape="round"
                      size="large"
                      block
                      class=""
                      @click="glyhCancel"
                      >放弃关联派位</a-button
                    >
                    <a-button
                      type="primary"
                      shape="round"
                      size="large"
                      block
                      class=""
                      @click="glyhConfirm"
                      >确定关联派位</a-button
                    >
                  </a-space>
                </div>
              </div>
            </div>
            <!-- 关联排位end -->

            <!-- v-if="glyhFlag" -->
            <!-- <a-col :span="12" v-if="glyhFlag">
              <a-form-item label="是否进行关联派位">
                <a-radio-group>
                  <a-row>
                    <a-col
                      :span="24"
                      v-for="(item, idx) in childrens"
                      :key="idx"
                    >
                      <div
                        @click="itemRadio(item)"
                        style="display: flex; align-items: center"
                      >
                        <div class="row">
                          <div class="icon">
                            <img
                              :src="require('@/assets/form/check.png')"
                              alt=""
                              v-if="glRegIds == item.stuGlIds"
                            />
                            <img
                              :src="require('@/assets/form/check_o.png')"
                              alt=""
                              v-else
                            />
                          </div>
                          <div class="name">{{ item.stuXm }}</div>
                          <div class="card">{{ item.stuZjh }}</div>
                          <div class="status done">{{ item.bzInfo }}</div>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </a-radio-group>
              </a-form-item>
              <div class="tip">
                重要提示：您勾选关联多胞胎后，多人将视为一人参加派位，成功则多人均被录取，末成功则多人均未被录取；若不勾选，则视为多人分别独立参加派位。
              </div>
            </a-col> -->
          </a-row>
        </div>
        <div class="saveBtn">
          <a-row :gutter="24">
            <a-col :span="7" :offset="5">
              <a-button
                type="primary"
                shape="round"
                size="large"
                block
                class="pre-button btn"
                @click="save(2)"
              >
                保存
              </a-button>
            </a-col>
            <a-col :span="7">
              <a-popconfirm
                placement="top"
                ok-text="确认"
                cancel-text="取消"
                @confirm="save(3)"
              >
                <template slot="title">
                  <p>温馨提示</p>
                  <p>确认提交填报信息并下载登记表吗？</p>
                </template>
                <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  block
                  class="btn"
                >
                  提交，并下载登记表
                </a-button>
              </a-popconfirm>
            </a-col>
          </a-row>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import pick from "lodash.pick";
import { getMbNotice } from "@/api/notice";
import { getSchoolList, getMbDetail, getIfGlyh, saveMbxb } from "@/api/form";
import { getFlag } from "@/api/common";

export default {
  components: {},
  data() {
    return {
      showNotice: true,
      disabled: false,
      notice: {},
      form: this.$form.createForm(this),
      glyhCur: "", // 关联派位  是否
      glyhFlag: false,
      schoolList: [], // 学区下的学校
      detailInfo: {},
      childrens: [],
      glRegIds: "",
      glyhModal: false, //
      glyhList: [
        { id: 1, name: "张三", idCade: "140106" },
        { id: 1, name: "张三", idCade: "140106" },
      ], // 关联派位
      glAbandon: false, // 放弃关联
      glConfirm: false, // 确认关联
    };
  },
  filters: {},
  methods: {
    // 多胞胎关联选择
    itemRadio(item) {
      console.log(888, item.stuGlIds);
      if (this.glRegIds == item.stuGlIds) {
        this.glRegIds = "";
      } else {
        this.glRegIds = item.stuGlIds;
      }
      console.log(999, this.glRegIds);
    },
    // 须知确认已阅
    know() {
      getFlag({
        paramKey: "mbxxSelect",
        schoolCode: localStorage.getItem("schoolCode"),
      }).then((res) => {
        if (res.data.paramValue == 1) {
          this.showNotice = false;
          this.getSchoolList();
        } else {
          this.$message.info("填报暂未开启");
        }
      });
    },
    // 温馨提示
    getNotice() {
      getMbNotice({
        fjType: this.type,
      }).then((res) => {
        console.log(res, "2233");
        this.notice = res.data;
      });
    },
    // 获取民办学校
    getSchoolList() {
      getSchoolList().then((res) => {
        this.schoolList = res.data;
        this.$nextTick(() => {
          this.getDetailInfo();
        });
      });
    },
    schoolChange(e) {
      // yxSchoolId 参数
      console.log(e);
      this.glAbandon = false;
      this.glConfirm = false;
      this.glRegIds = "";
      this.getIfGlyh(e);
    },
    getDetailInfo() {
      getMbDetail().then((res) => {
        this.detailInfo = res.data;
        this.glRegIds = this.detailInfo.glRegIds;
        if (this.detailInfo.yxSchoolId) {
          this.getIfGlyh(this.detailInfo.yxSchoolId);
        }
        this.setFormData();
      });
    },
    // 是否显示关联派位
    getIfGlyh(yxSchoolId) {
      getIfGlyh({
        yxSchoolId: yxSchoolId,
      }).then((res) => {
        if (res.data.length > 0) {
          this.glyhModal = true;
          this.glyhList = res.data;
        } else {
          this.glyhModal = false;
        }
      });
    },
    save(type) {
      this.form.validateFields((err, values) => {
        // 默认 1 保存 2  提交 3
        values.xbxxStatus = type;
        values.id = this.detailInfo.id;
        values.glRegIds = this.glRegIds;
        this.schoolList.forEach((ele, idx) => {
          if (ele.id == values.yxSchoolId) {
            values.yxSchoolName = ele.name;
          }
        });
        values.schoolCode = localStorage.getItem("schoolCode");
        values.djType = localStorage.getItem("stage");
        values.djTypeValue = localStorage.getItem("stageText");
        if (!err) {
          saveMbxb(values).then((res) => {
            if (res.code == 0) {
              this.$message.success(res.description);
              if (type == 3) {
                window.open(
                  `/fast-imagine/mobile/yw/exportDjb?fjType=2&userCode=${localStorage.getItem(
                    "userCode"
                  )}`
                );
                this.$emit("reload");
              }
            } else {
              this.$message.error(res.description);
            }
          });
        }
      });
    },
    setFormData() {
      const formData = pick(this.detailInfo, [
        "id",
        "jhr",
        "jhrdh",
        "xb",
        "xm",
        "yxSchoolId",
        "yxSchoolName",
        "yxSchoolTypeText",
        "yxSchoolType",
        "zjh",
      ]);
      this.$nextTick(() => {
        this.form.setFieldsValue(formData);
      });
    },
    // 关联派位 -- 取消
    glyhCancel() {
      this.glyhModal = false;
      this.glAbandon = true;
    },
    // 关联派位 -- 确定
    glyhConfirm() {
      this.glyhModal = false;
      this.glConfirm = true;

      let arr = []
      this.glyhList.forEach(item =>{
        arr.push(item.regId)
      })

      this.glRegIds = arr.toString()
    },
  },
  mounted() {
    this.getNotice();
    // this.$nextTick(() => {
    // });
  },
};
</script>

<style lang="less" scoped>
.tip {
  color: #ff7575;
  font-size: 14px;
}
.notice {
  .conWrap {
    padding: 52px 77px 32px;
    .title {
      color: #333;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 24px;
    }
  }
  .btnWrap {
    text-align: center;
  }
  .btn {
    margin: 24px auto 16px;
    width: 280px;
    height: 44px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  }
}
.section {
  .part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7f7f7;
    padding: 8px 16px;
    margin-bottom: 12px;
    margin-top: 28px;
  }
  .title {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .line {
    display: inline-block;
    width: 3px;
    height: 16px;
    background: #35ab61;
    border-radius: 2px;
    margin-right: 6px;
  }
  .scan {
    font-size: 14px;
    color: #35ab61;
    cursor: pointer;
  }
  .del {
    font-size: 14px;
    color: #ff5454;
  }
  .icon {
    margin-right: 6px;
  }
  .lookDemo {
    font-size: 14px;
    color: #35ab61;
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 8px;
  }
}
.self_radio {
  .ant-radio-wrapper {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 40px;
    line-height: 27px;
    padding: 6px 11px;
    width: 181px;
    &:last-child {
      margin-right: 0;
    }
  }
}
/deep/ .ant-form-item-control {
  line-height: inherit;
}
/deep/ .ant-form-item {
  margin-bottom: 12px;
}
/deep/ .ant-input {
  font-size: 14px;
}
.saveBtn {
  margin-top: 28px;
  margin-bottom: 16px;
  .btn {
    height: 44px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  }
  .pre-button {
    border-color: #35ab61;
    background: #fff;
    color: #35ab61;
    box-shadow: none;
  }
}
.row {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 6px 0;
  .icon {
    img {
      width: 20px;
      height: 20px;
    }
  }
  .name {
    color: #000;
    padding-left: 2px;
  }
  .card {
    color: #666;
    padding-left: 8px;
  }
  .status {
    color: #999;
    padding-left: 20px;
  }
  .done {
    color: #35ab61;
  }
}
.glyh-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.glyh-content {
  width: 50%;
  margin: 100px auto 0;
  padding: 30px 60px;
  background: #fff;
}
.glyh-list {
  margin: 30px 0;
  background: #e9e9e9;

  div {
    padding: 10px 20px;
  }
}
.glyh-footer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
</style>
