<template>
  <div class="detail">
    <div class="section">
      <div class="part">
        <div class="title"><div class="line"></div>学生信息</div>
      </div>
      <div class="card">
        <div class="detailItem">
          <div class="itemTitle">姓名：</div>
          <div class="itemContent">{{detailInfo.xm}}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">性别：</div>
          <div class="itemContent">{{detailInfo.xb == 1 ? '男' : '女'}}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">证件号码：</div>
          <div class="itemContent">{{detailInfo.zjh}}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">监护人姓名：</div>
          <div class="itemContent">{{detailInfo.jhr}}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">监护人手机号：</div>
          <div class="itemContent">{{detailInfo.jhrdh}}</div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="part">
        <div class="title"><div class="line"></div>选报信息</div>
      </div>
      <div class="card">
        <div class="detailItem">
          <div class="itemTitle">选报民办学校：</div>
          <div class="itemContent">{{detailInfo.yxSchoolName}}</div>
        </div>
        <div class="detailItem" v-if="detailInfo.glRegXms">
          <div class="itemTitle">多胞胎关联信息：</div>
          <div>
            <template v-for="item in  detailInfo.glRegInfos">
              <div class="itemContent" :key="item.id">{{item.xm}}   {{item.zjh}}</div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="btnWrap">
      <a-button type="primary" shape="round" size="large" block class="btn"  @click="toTable(2)"> 
      下载登记表 
      </a-button>
    </div>

    <!-- 图片预览 -->
    <a-modal :width="800" :visible="previewVisible" :footer="null" @cancel="imgHandleCancel">
      <img alt="example" style="width: 100%" :src="imgUrl" />
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { getMbDetail } from '@/api/form';

export default {
  data() {
    return {
      imgOrigin: this.$store.state.fileUrl,
      detailInfo: {},
      previewVisible: false, // 图片预览
      imgUrl: '',
    };
  },
  filters: {
    dateFilter(val) {
      return moment(+val).format('YYYY-MM-DD')
    }
  },
  methods: {
    toTable(type) {
      window.open(`/fast-imagine/mobile/yw/exportDjb?fjType=${type}&userCode=${localStorage.getItem("userCode")}`)
    },
    previewImage (url) {
      this.previewVisible = true
      this.imgUrl = url
    },
    imgHandleCancel () {
      this.previewVisible = false
    },
    getDetailInfo() {
      getMbDetail().then(res => {
        this.detailInfo = res.data
      })
    },
  },
  mounted() {
    this.getDetailInfo()
  },
}
</script>

<style lang="less" scoped>
.detail{
  padding: 16px 16px 0;
  .btnWrap {
    text-align: center;
  }
  .btn {
    margin: 24px auto 16px;
    width: 280px;
    height: 44px;
    box-shadow: 0px 7px 12px 0px 
      rgba(53, 171, 97, 0.33);
  }
}
.section{
  .part{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7f7f7;
    padding: 8px 16px;
    margin-bottom: 20px;
    margin-top: 16px;
  }
  .title{
    font-size: 16px;
    color: #333;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .line{
    display: inline-block;
    width: 3px;
    height: 16px;
    background: #35ab61;
    border-radius: 2px;
    margin-right: 6px;
  }
  .del{
    font-size: 14px;
    color: #ff5454;
  }
}
.card{
  display: flex;
  flex-wrap: wrap;
  .item{
    display: flex;
    flex-wrap: wrap;
  }
  .familyItem {
    width: 100%;
    .num{
      font-size: 14px;
      font-weight: 600;
      color: #666;
      margin-bottom: 20px;
    }
  }
  .detailItem{
    display: flex;
    width: 49%;
    font-size: 14px;
    margin-right: 1%;
    margin-bottom: 16px;
  }
  .file {
    width: 100%;
    .itemContent{
      display: flex;
      flex-wrap: wrap;
    }
    .img{
      margin-right: 8px;
      margin-bottom: 8px;
    }
    img{
      width: 68px;
      height: 68px;
      
    }
  }
  .itemTitle{
    color: #666;
  }
  .itemContent{
    color: #333;
  }
}
</style>