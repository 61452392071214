import { render, staticRenderFns } from "./mbxbForm.vue?vue&type=template&id=08a24e2f&scoped=true"
import script from "./mbxbForm.vue?vue&type=script&lang=js"
export * from "./mbxbForm.vue?vue&type=script&lang=js"
import style0 from "./mbxbForm.vue?vue&type=style&index=0&id=08a24e2f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a24e2f",
  null
  
)

export default component.exports